import Image from "next/image";
import React, {useState} from "react";
import UXIcon from "../../ux/icon";
import {pages, products} from "./content";
import css from "./HomePageComponent.module.scss";
import {Navigation} from "./Navigation";
import {ProductPage} from "./products/ProductPage";
import {Quotes} from "./Quotes";
export const HomePageComponent = (props) => {
    const {bodyClasses} = props;

    const allPages = _.merge(_.clone(products), _.clone(pages));

    // tracking system for main nav (product selection)
    const [pageKey, setPageKey] = useState(null);

    //menu toggle for mobile nav
    const [showSlider, setShowSlider] = useState(false);

    const onClickShowSlider = (e) => {
        setShowSlider(!showSlider);
    };

    // click handler
    const onClickTogglePage = (name) => {
        return (e) => {
            if (pageKey == name && _.has(allPages, name)) {
                setPageKey(null);
            } else {
                setPageKey(name);
            }
            setShowSlider(false);
        };
    };

    // props for downstream controls, that need to track navigation and click

    const mobile = props?.viewport?.orientation == "portrait";

    const widgetProps = {
        ...props,
        allPages,
        pageKey,
        showSlider,
        onClickShowSlider,
        onClickTogglePage,
        mobile
    };

    return (
        <div className={classnames(css.container, bodyClasses(props))}>
            <div className={css.centered}>
                <UXIcon name="arcaneus_logo"></UXIcon>
            </div>
        </div>
    );

    return (
        <>
            <Overlay />

            <div className={classnames(css.container, bodyClasses(props))}>
                <Navigation {...widgetProps} />
                <Headline mobile={mobile} />
                <Quotes mobile={mobile} />
            </div>

            <ProductPage {...widgetProps} />

            <footer className={css.footer}>
                <div>Copyright &#169; 2024 Arcaneus. All rights reserved.</div>
            </footer>
        </>
    );
};

// layered images and videos
const Overlay = (props) => {
    const facilityHack = "/images/hacking.jpg";
    const typingVideo = "/videos/typing.mp4";

    return (
        <div className={css.overlay}>
            <video className={css.typingVideo} src={typingVideo} loop={true} autoPlay={true} muted={true} />
            <Image className={css.ai} src={facilityHack} priority={true} fill={true} objectFit="cover" alt="AI Hacking" />
        </div>
    );
};

// top headline on page
const Headline = (props) => {
    const {mobile} = props;
    return (
        <div className={classnames(css.headline, {[css.mobile]: mobile})}>
            <span>Arcaneus</span> delivers cutting-edge solutions for accelerated, secure data transfer, fortified storage, and stealth networking—all backed by <b>military-grade, quantum-proof encryption</b>. Our technologies are designed to safeguard your most sensitive information, ensuring
            unparalleled security, speed, and resilience in the face of evolving cyber threats in today's digital world.
        </div>
    );
};
